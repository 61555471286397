










































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { InstanceOf } from '@/lib/types';
import { BaseModel } from '@/models/internal';
import { Sync } from 'vuex-pathify';

/**
 * Default func if not provided in props
 */
const noop = () => false;

@Component({
  name: 'TableRowActions',
})
export default class TableRowActions extends Vue {
  @Sync('ui/global@loading')
  protected uiLoading!: boolean;

  /**
   * A record as a data table row
   */
  @Prop({ required: true })
  protected readonly record!: BaseModel;

  /**
   * Callback for when `view` button is clicked
   */
  @Prop({ required: false, default: false })
  protected readonly view!: ((record: InstanceOf<BaseModel>) => Promise<any>) | false;

  /**
   * Callback for when `edit` button is clicked
   */
  @Prop({ required: false, default: false })
  protected readonly edit!: ((record: InstanceOf<BaseModel>) => Promise<any>) | false;

  /**
   * Callback for when `destroy` button is clicked
   */
  @Prop({ required: false, default: false })
  protected readonly destroy!: ((record: InstanceOf<BaseModel>) => Promise<any>) | false;

  /**
   * Callback for when `approve` button is clicked
   */
  @Prop({ required: false, default: false })
  protected readonly approve!: ((record?: InstanceOf<BaseModel>) => Promise<any>) | false;

  /**
   * Callback for when `deny` button is clicked
   */
  @Prop({ required: false, default: false })
  protected readonly deny!: ((record?: InstanceOf<BaseModel>) => Promise<any>) | false;

  /**
   * Callback to check for view permission on the model instance
   */
  @Prop({ required: false, default: () => noop })
  protected readonly canRead!: (record?: InstanceOf<BaseModel>) => boolean;

  /**
   * Callback to check for edit permission on the model instance
   */
  @Prop({ required: false, default: () => noop })
  protected readonly canUpdate!: (record?: InstanceOf<BaseModel>) => boolean;

  /**
   * Callback to check for approval permission on the model instance
   */
  @Prop({ required: false, default: () => noop })
  protected readonly canUpdateStatus!: (record?: InstanceOf<BaseModel>) => boolean;

  /**
   * Callback to check for view permission on the model instance
   */
  @Prop({ required: false, default: () => noop })
  protected readonly canDestroy!: (record?: InstanceOf<BaseModel>) => boolean;
}
